module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mathieu Mencé | Web Graphics & Fullstack Engineer","short_name":"Matnard's","start_url":"/","background_color":"#666666","theme_color":"#666666","display":"minimal-ui","icon":"src/media/profile-17.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
